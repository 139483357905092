/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&family=Inter:wght@300;400;500&display=swap');

:root {
  --primary-color: #b07d62; /* Warm terracotta */
  --secondary-color: #e6ccb2; /* Soft beige */
  --accent-color: #7d9d9c; /* Muted sage */
  --background-color: #f8f5f2; /* Warm off-white */
  --text-color: #3c3c3c; /* Soft black */
  --text-light: #6c6c6c; /* Medium gray */
  --card-background: #ffffff; /* White */
  --border-color: #e6e0d4; /* Light beige */
  --shadow-color: rgba(176, 125, 98, 0.08); /* Soft shadow */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.6;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.app.loaded {
  opacity: 1;
}

/* Navigation Bar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: var(--background-color);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid var(--border-color);
}

.nav-brand {
  font-family: 'Fraunces', serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links button {
  background: none;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 0.95rem;
  color: var(--text-light);
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

.nav-links button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.nav-links button:hover {
  color: var(--primary-color);
}

.nav-links button:hover::after {
  width: 100%;
}

.nav-links button.active {
  color: var(--primary-color);
}

.nav-links button.active::after {
  width: 100%;
}

/* Main Content */
.content {
  flex: 1;
}

/* Section Styling */
.section {
  padding: 5rem 0;
  position: relative;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.app.loaded .section {
  opacity: 1;
  transform: translateY(0);
}

.app.loaded .section:nth-child(1) {
  transition-delay: 0.1s;
}

.app.loaded .section:nth-child(2) {
  transition-delay: 0.2s;
}

.app.loaded .section:nth-child(3) {
  transition-delay: 0.3s;
}

.app.loaded .section:nth-child(4) {
  transition-delay: 0.4s;
}

.app.loaded .section:nth-child(5) {
  transition-delay: 0.5s;
}

.section:nth-child(odd) {
  background-color: var(--background-color);
}

.section:nth-child(even) {
  background-color: #ffffff;
}

.section-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
}

.section-title {
  font-family: 'Fraunces', serif;
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 2.5rem;
  position: relative;
  display: inline-block;
  font-weight: 500;
}

.section-title::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  bottom: -8px;
  left: 0;
  background-color: var(--primary-color);
}

/* About Section */
.profile-container {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
}

.profile-image {
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-text {
  flex: 1;
  padding-top: 1rem;
}

.profile-text h1 {
  font-family: 'Fraunces', serif;
  font-size: 2.5rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.profile-text h2 {
  font-size: 1.3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-family: 'Fraunces', serif;
}

.profile-text p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: var(--text-light);
  line-height: 1.7;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 1.5rem;
  padding-left: 0;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0;
  font-size: 0.95rem;
}

.icon {
  font-style: normal;
  color: var(--primary-color);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 400;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--secondary-color);
  font-size: 0.9rem;
}

.social-links a:hover {
  background-color: var(--primary-color);
  color: white;
}

/* Skills Section */
.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.skill-category {
  flex: 1;
  min-width: 250px;
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px var(--shadow-color);
  transition: transform 0.3s ease;
}

.skill-category:hover {
  transform: translateY(-5px);
}

.skill-category h3 {
  font-family: 'Fraunces', serif;
  color: var(--text-color);
  margin-bottom: 1.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
  font-weight: 500;
}

.skill-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.skill-list li {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  font-size: 0.85rem;
  transition: background-color 0.3s ease;
}

.skill-list li:hover {
  background-color: var(--primary-color);
  color: white;
}

/* Experience Section */
.experience-item {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1.8rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 20px var(--shadow-color);
  transition: transform 0.3s ease;
}

.experience-item:hover {
  transform: translateY(-5px);
}

.experience-header {
  margin-bottom: 1.2rem;
}

.experience-header h3 {
  font-family: 'Fraunces', serif;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.company {
  font-weight: 400;
  color: var(--primary-color);
}

.date {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-top: 0.3rem;
}

.experience-details {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.experience-details li {
  margin-bottom: 0.7rem;
  color: var(--text-light);
  font-size: 0.95rem;
}

/* Projects Section */
.projects-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.project-item {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.project-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.project-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.project-header h3 a {
  color: #4a148c;
  text-decoration: none;
  transition: color 0.2s ease;
}

.project-header h3 a:hover {
  color: #6a1b9a;
}

.project-item p {
  color: #666;
  line-height: 1.6;
  margin: 1rem 0;
}

.project-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: 2rem;
}

.project-skills span {
  background: #f5f5f5;
  color: #666;
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  white-space: nowrap;
}

/* Education Section */
.education-timeline {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  gap: 60px;
}

.timeline-item {
  position: relative;
  flex: 1;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1rem 0;
  position: relative;
}

.timeline-item .education-logo {
  margin: 0;
}

.education-logo {
  flex-shrink: 0;
}

.umd-logo, .mc-logo {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Fraunces', serif;
  font-weight: 500;
  font-size: 1rem;
  color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.timeline-content:hover .umd-logo,
.timeline-content:hover .mc-logo {
  transform: scale(1.05);
}

.umd-logo {
  background-color: #e21833; /* UMD red */
}

.mc-logo {
  background-color: #522d80; /* MC purple */
}

.education-details {
  flex: 1;
}

.education-details h3 {
  font-family: 'Fraunces', serif;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.degree {
  font-weight: 400;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.date {
  color: var(--text-light);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.gpa {
  font-weight: 400;
  color: var(--text-color);
  font-size: 0.9rem;
}

.honors {
  color: var(--primary-color);
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

/* Responsive design for timeline */
@media (max-width: 768px) {
  .education-timeline {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 20px 0;
  }

  .timeline-item {
    max-width: 100%;
  }

  .timeline-content {
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
  }

  .education-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Footer */
.footer {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 1.5rem;
  margin-top: 0;
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
}

/* Responsive Designs */
@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }
  
  .profile-image {
    width: 200px;
    height: 200px;
    margin-bottom: 1.5rem;
  }
  
  .profile-text h1 {
    font-size: 2rem;
  }
  
  .profile-text h2 {
    font-size: 1.2rem;
  }
  
  .section-title {
    font-size: 1.7rem;
  }
  
  .section-title::after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .social-links {
    justify-content: center;
  }
  
  .contact-info {
    align-items: center;
  }
  
  .education-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .navbar {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  .nav-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
}
